import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../components/Layout/Layout'
import { Navigation } from 'react-minimal-side-navigation'
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css'

// markup
const Webhook = () => {
  useLayoutEffect(() => {
    var s = document.createElement('script')
    s.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/npm/redoc@latest/bundles/redoc.standalone.js'
    )
    s.onload = () => console.log('redoc script added')
    document.body.appendChild(s)
  }, [])

  return (
    <Layout>
      <title>Consupedia Webhook Documentation</title>
      {/* <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Webhook</h1>
          </div>
        </div>
      </div> */}
      <section className="alignfull sidebar-page">
        <div className="nav">
          <>
            <Navigation
              // you can use your own router's api to get pathname
              activeItemId="/webhook/#about"
              onSelect={({ itemId }) => {
                // maybe push to the route
                navigate(itemId)
              }}
              items={[
                {
                  title: 'Webhook',
                  itemId: '/webhook',
                  subNav: [
                    {
                      title: 'About webhooks',
                      itemId: '/webhook/#about'
                    },
                    {
                      title: 'Creating webhooks',
                      itemId: '/webhook/#create'
                    },
                    {
                      title: 'Securing your webhooks',
                      itemId: '/webhook/#secure'
                    }
                  ]
                }
              ]}
            />
          </>
        </div>
        <div className="section-container">
          <div id="about">
            <h2>About webhooks</h2>
            <p>
              Webhooks is a convenient way to get notified when a product is
              updated with new information.
            </p>
            <p>
              Webhooks allow you to build or set up integrations which subscribe
              to product update events. Rather than requiring you to pull
              information via our API, webhooks will <strong>push</strong>{' '}
              information to your endpoint. When there is new data about a
              subscribed product, we'll send a HTTP POST payload to the
              webhook's configured URL. Webhooks is a great way to keep your
              product information up to date.
            </p>
            <span className="spacer" id="create"></span>
            <h2>Creating webhooks </h2>
            <p>
              Subscribe to products and assign an URL to your new webhook by
              contacting Consupedia support at{' '}
              <a href="mailto:tech-support@consupedia.com" target="_blank">
                tech-support@consupedia.com
              </a>
              .
            </p>
            <p>
              At this time new webhooks are setup by Consupedia Support. Send a
              list of products (GTIN or Consupedia Product Id) and the endpoint
              URL and we will setup the webhook. The URL needs to be full,
              valid, and publicly accessible. If you would like to use the data
              for a research project, please add a description of your work and
              how the Consupedia data will be used.
            </p>
            <span className="spacer" id="secure"></span>
            <h2>Securing your webhooks</h2>
            <p>
              Ensure your server is only receiving the expected Consupedia
              requests for security reasons.
            </p>
            <p>
              Once your server is configured to receive payloads, it'll listen
              for any payload sent to the endpoint you configured. For security
              reasons, you probably want to limit requests to those coming from
              Consupedia. There are a few ways to go about this--for example,
              you could opt to allow requests from Consupedias's IP address--but
              a far easier method is to set up a secret token and validate the
              information.
            </p>
            <h3>Setting a secret token</h3>
            <p>
              Along with your request for a webhook, send a random string with
              high entropy (e.g., by taking the output of{' '}
              <code>ruby -rsecurerandom -e 'puts SecureRandom.hex(20)'</code> at
              the terminal).
            </p>
            <p>
              Consupedia will use this to create a hash signature with each
              payload sent by the webhook. This hash signature is included with
              the headers of each request as <code>X-Hub-Signature-256</code>.
            </p>
            <p>
              The service receiving the webhook requests should be configured to
              validate that the hash matches a hash created with your secret
              token. Consupedia uses a HMAC hex digest to compute the hash, so
              you should configure your server to use the same digest algorithm
              to validate.
            </p>
            <span className="spacer" id="create"></span>
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>You need any help with the Consupedia platform? Get in touch:</p>
            <p>
              <strong>
                <a href="mailto:tech-support@consupedia.com">
                  tech-support@consupedia.com
                </a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Webhook
